<template>
	<div class="page-container">
		<Head />
		<div class="page-banner" :style="{backgroundImage:'url('+banner+')'}">
			<div class="container banner-align-center">
				<div class="banner-text wow fadeInDown">
					<div class="ch">赋能行业</div>
					<div class="en">EMPOWERING INDUSTRY</div>
					<img src="../../src/assets/image/down-arrow.png" alt="" />
				</div>
			</div>
		</div>
		<div class="page-wrapper">
			<div class="container">
				<div class="ena-row">
					<el-row :gutter="20">
						<el-col :xs="12" :span="8">
							<div class="ena-card hover-up">
								<div class="ena-photo">
									<img src="../../src/assets/image/fu-pic-1.png" alt="" />
									<div class="ena-icon">
										<img src="../../src/assets/image/icon-1.png" alt="" />
										<div class="e-label">一般贸易</div>
									</div>
								</div>
								<div class="ena-grid">
									<div class="tt">一般贸易</div>
									<div class="desc">帮助客户进行0110海关申报，通过银行进行收汇并开展出口退税</div>
								</div>
							</div>
						</el-col>
						<el-col :xs="12" :span="8">
							<div class="ena-card hover-up">
								<div class="ena-photo">
									<img src="../../src/assets/image/fu-pic-2.png" alt="" />
									<div class="ena-icon">
										<img src="../../src/assets/image/icon-2.png" alt="" />
										<div class="e-label">进口电商</div>
									</div>
								</div>
								<div class="ena-grid">
									<div class="tt">进口电商</div>
									<div class="desc">为进口电商提供收单、跨境付款、海关推单、换汇分发完整资金链路的综合解决方案，解决进口电商资金烦扰</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="ena-row">
					<el-row :gutter="20">
						<el-col :xs="12" :span="8">
							<div class="ena-card hover-up">
								<div class="ena-photo">
									<img src="../../src/assets/image/fu-pic-3.png" alt="" />
									<div class="ena-icon">
										<img src="../../src/assets/image/icon-3.png" alt="" />
										<div class="e-label">出口电商</div>
									</div>
								</div>
								<div class="ena-grid">
									<div class="tt">出口电商</div>
									<div class="desc">帮助出口电商客户进行9610出口申报，海外销售资金回款后进行税务核定或者出口退税</div>
								</div>
							</div>
						</el-col>
						<el-col :xs="12" :span="8">
							<div class="ena-card hover-up">
								<div class="ena-photo">
									<img src="../../src/assets/image/fu-pic-3.png" alt="" />
									<div class="ena-icon">
										<img src="../../src/assets/image/icon-3.png" alt="" />
										<div class="e-label">出口电商</div>
									</div>
								</div>
								<div class="ena-grid">
									<div class="tt">9710出口</div>
									<div class="desc">帮助客户按9710方式报关出口申报，海外销售资金回款并申报退税</div>
								</div>
							</div>
						</el-col>
						<el-col :xs="12" :span="8">
							<div class="ena-card hover-up">
								<div class="ena-photo">
									<img src="../../src/assets/image/fu-pic-3.png" alt="" />
									<div class="ena-icon">
										<img src="../../src/assets/image/icon-3.png" alt="" />
										<div class="e-label">出口电商</div>
									</div>
								</div>
								<div class="ena-grid">
									<div class="tt">9810出口</div>
									<div class="desc">帮助出口电商进行9810海外仓模式出口，并通过银行进行海外销售资金回款</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
		},
		data() {
			return {
				banner:require('../assets/image/funeng.jpg')
			};
		},
		methods: {

		},
		mounted() {
			new this.$wow.WOW().init();
		}
	}
</script>
<style type="text/css">
	
	.ena-row{
		margin-bottom: 60px;
	}
	.ena-row:last-child{
		margin-bottom: 0;
	}
	
	
	
	
	
	
	
</style>